<template>
  <div id="threatMap" class="landing-block pt-5">
    <div class="container px-3">
      <div class="row justify-content-between align-items-center my-4">
        <div class="col-lg-5 col-xl-4 text-lg-left text-center mx-auto">
          <div class="text-lighter text-tiny font-weight-bold mb-3">
            LIVE TRAFFIC
          </div>
          <h1 class="display-4 font-secondary font-weight-semibold mb-4">
            Threat Map
          </h1>
          <p class="text-muted">
            The threat map showcases global Abuser-Port Scanner bots and Threat-Vulnerability attackers by country in percentage format.
            This visual representation tracks the prevalence of these threats, offering an insightful geographic breakdown of their occurrences worldwide.
          </p>
        </div>
        <div class="col-lg-7 col-xl-8 mt-5 mt-lg-0">
          <vue-datamaps :geographyConfig="geographyConfig"
                        :fills="fills"
                        :data="data"
                        popupTemplate
                        @custom:popup="popupTemplate">
            <div slot="hoverinfo" class="hoverinfo" style="white-space: pre-line;">
              <strong>{{ popupData.name }}</strong>
              <p v-if="popupData.abuser">Abuser: {{ popupData.abuser }}</p>
              <p v-if="popupData.attacker">Attacker: {{ popupData.attacker }}</p>
            </div>
          </vue-datamaps>
        </div>
      </div>
      <div class="row">
        <!-- Top 10 charts -->
        <div class="col-sm-6 col-xl-6">
          <b-card no-body class="mb-4">
            <b-card-header header-tag="h6" class="with-elements">
              <div class="card-header-title">Abuser Source Countries</div>
            </b-card-header>
            <div class="table-responsive">
              <table class="table card-table m-0">
                <tbody>
                  <tr v-for="(abuser, index) in abuser_data" :key="index">
                    <td class="border-top-0 text-nowrap align-middle">
                      {{ abuser.country }}
                    </td>
                    <td class="w-100 border-top-0 align-middle">
                      <div class="progress" style="height: 4px;">
                        <div class="progress-bar bg-danger" :style="{width:`${abuser.percent}%`}"></div>
                      </div>
                    </td>
                    <td class="border-top-0 text-nowrap align-middle">
                      {{ abuser.percent }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </div>
        <div class="col-sm-6 col-xl-6">
          <b-card no-body class="mb-4">
            <b-card-header header-tag="h6" class="with-elements">
              <div class="card-header-title">Attacker Source Countries</div>
            </b-card-header>
            <div class="table-responsive">
              <table class="table card-table m-0">
                <tbody>
                  <tr v-for="(attacker, index) in attacker_data" :key="index">
                    <td class="border-top-0 text-nowrap align-middle">
                      {{ attacker.country }}
                    </td>
                    <td class="w-100 border-top-0 align-middle">
                      <div class="progress" style="height: 4px;">
                        <div class="progress-bar bg-danger" :style="{width:`${attacker.percent}%`}"></div>
                      </div>
                    </td>
                    <td class="border-top-0 text-nowrap align-middle">
                      {{ attacker.percent }}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import csh from "@/csh";
import createSecurity from "../csl";

export default {
  name: "AppThreatMap",
  data: () => ({
    scope: 'world',
    width: 600,
    height: 300,
    geographyConfig: {
      highlightOnHover: false
    },
    fills: {
      'Threat': '#dc3545',
    },
    data: {},
    abuser_data: [],
    attacker_data: [],
    popupData: {
      name: '',
      abuser: '',
      attacker: ''
    }
  }),
  methods: {
    popupTemplate ({ geography, datum }) {
      if (!datum) {
        this.popupData = {
          name: '',
          abuser: '',
          attacker: ''
        }
        return
      }
      this.popupData = {
        name: geography.properties.name,
        abuser: datum.abuser,
        attacker: datum.attacker
      }
    },
    getThreatMapData () {
      const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.tma1
      this.axios.get(csh.gcu() + currentUri
      ).then(response => {
        if (response.status === 200) {
          let response_data = response.data
          this.abuser_data = response_data.abuser
          this.attacker_data = response_data.attacker
          var threat_map_data = {}
          this.abuser_data.forEach((function (arrayItem) {
            if(!(arrayItem.iso3 in threat_map_data)){
              threat_map_data[arrayItem.iso3] = {
                'fillKey': 'Threat'
              }
            }
            threat_map_data[arrayItem.iso3].country = arrayItem.country
            threat_map_data[arrayItem.iso3].abuser = arrayItem.percent + '%'
          }))
          this.attacker_data.forEach((function (arrayItem) {
            if(!(arrayItem.iso3 in threat_map_data)){
              threat_map_data[arrayItem.iso3] = {
                'fillKey': 'Threat'
              }
            }
            threat_map_data[arrayItem.iso3].country = arrayItem.country
            threat_map_data[arrayItem.iso3].attacker = arrayItem.percent + '%'
          }))
          this.data = threat_map_data
        }
        this.chi = false
      }).catch(function (e) {
        console.log('error', e)
        self.showInformation(true, 'Threat map data retrieval failed.')
      })
    }
  },
  created() {
      if (this.$route.query.R) {
        // 1 sec delay in case of a self-service approval attempt
        setTimeout(this.getThreatMapData, 1000)
      } else {
        this.getThreatMapData()
      }
  }
};
</script>

<style>
svg {
  overflow: visible;
}
.datamap {
    width: 100%;
    height: 100%;
}
.hoverinfo {
  font-size: small;
  background-color: white;
  padding: 2px;
}
.hoverinfo p {
  padding: 0;
  margin: 0;
}
</style>