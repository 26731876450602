import dcs from './dcs.js'

export default {
  // apiVersion
  avr2: dcs('S92MQL2Fwa', 2),
  // getCurrentUrl
  gcu: function () {
    return process.env.VUE_APP_API_URL || window.location.origin
  },
  msd2: dcs('2VuZAL21lc3NhZ2Uvc', 2),
  rqa1: dcs('92ZS8L3JlcXVlc3QvYXBwcm', 1),
  rqs0: dcs('ibWl0L3JlcXVlc3Qvc3V', 0),
  chi2: dcs('2lwLwL2NoZWNrL', 2),
  chp2: dcs('nRzLwL2NoZWNrL3Bvc', 2),
  tma1: dcs('1tYXAL3RocmVhdC', 1),
  ske2: dcs('TFRNgaEVQUVk0YUpISEtnSFFFVTdmM09NR0FubHNNOXRWbGtvc2RlY2pHWnQ5eThrOTBYeVY3aGlScVd2T0lnS', 2),
  slt2: dcs('FBaWgM2l5aVpwS', 2),
  // cipher
  cpr: salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).slice(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
      .map(textToChars)
      .map(applySaltToChar)
      .map(byteHex)
      .join('');
  }
}
