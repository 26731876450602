<template>
  <div>
    <b-card-body v-show="(getPorts).length">
      <table class="table card-table">
        <tbody>
        <tr>
          <th scope="row" class="w-25">Open Ports</th>
          <td>
            <b-btn v-for="port in getPorts" :key="port" variant="primary" class="mr-1 mb-1">{{ port }}</b-btn>
          </td>
        </tr>
        </tbody>
      </table>
    </b-card-body>
    <b-card-body v-show="Object.keys(getVulnerabilities).length">
      <table class="table card-table">
        <thead>
        <tr>
          <th>
            <i class="ion ion-ios-warning"></i>
            Vulnerabilities
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td colspan="2">
            <div class="text-muted text-tiny mt-1"><small class="font-weight-normal">
              Note: the device may not be impacted by all of these issues. The vulnerabilities are implied based on the
              software and version.
            </small></div>
          </td>
        </tr>
        <tr v-for="(vulnValue, vulnKey) in getVulnerabilities" :key="vulnKey">
          <th scope="row" class="w-25">{{ vulnKey }}</th>
          <td>{{ vulnValue }}</td>
        </tr>
        </tbody>
      </table>
    </b-card-body>
  </div>
</template>

<script>
export default {
  props: ['searchPortResult'],
  name: "AppSearchPortResult",
  computed: {
    getPorts() {
      return this.searchPortResult && this.searchPortResult.ports || []
    },
    getVulnerabilities() {
      return this.searchPortResult && this.searchPortResult.vulnerabilities || {}
    }
  }
}
</script>
