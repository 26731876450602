<template>
  <!-- Header -->
  <div
      class="jumbotron ui-hero ui-mh-100vh ui-bg-cover"
      style="background-image: url('img/bg.png')"
  >
    <app-search-ip v-show="!searchIpResult&&!selfServiceForm&&!selfServiceThankYou"></app-search-ip>
    <app-search-ip-result v-show="searchIpResult"
                          :search-ip-address="searchIpAddress"
                          :search-ip-result="searchIpResult"
                          :search-port-result="searchPortResult"
                          :do-clean-start="doCleanStart"></app-search-ip-result>
    <app-self-service v-show="selfServiceForm"></app-self-service>
    <app-self-service-thank-you v-show="selfServiceThankYou"></app-self-service-thank-you>
  </div>
  <!-- / Header -->
</template>

<script>
import AppSearchIp from "./AppSearchIp";
import AppSearchIpResult from "./AppSearchIpResult";
import AppSelfService from "./AppSelfService";
import AppSelfServiceThankYou from "./AppSelfServiceThankYou";

import csh from "@/csh";
import createSecurity from "../csl";

export default {
  props: ['doCleanStart', 'activateSelfService', 'activateSelfServiceThankYou'],
  name: "AppHeader",
  components: {
    AppSearchIp,
    AppSearchIpResult,
    AppSelfService,
    AppSelfServiceThankYou
  },
  data: () => ({
    searchIpAddress: null,
    searchIpResult: null,
    searchPortResult: null,
    selfServiceForm: false,
    selfServiceThankYou: false
  }),
  watch: {
    doCleanStart: function (value) {
      if (value) {
        this.searchIpAddress = null
        this.searchIpResult = null
        this.searchPortResult = null
        this.$parent.doCleanStart = false
        this.$parent.isActiveSearch = false
        this.selfServiceForm = false
        this.selfServiceThankYou = false
        this.$parent.isActiveSelfService = false
      }
    },
    searchIpResult: function (value) {
      if (value) {
        this.$emit('activeSearch')
      }
    },
    activateSelfService: function (value) {
      if (value) {
        this.searchIpAddress = null
        this.searchIpResult = null
        this.searchPortResult = null
        this.selfServiceForm = true
        this.selfServiceThankYou = false
      }
    },
    activateSelfServiceThankYou: function (value) {
      if (value) {
        this.selfServiceThankYou = true
      }
    }
  },
  mounted(){
    if (this.$route.query.R) {
      // approve with Thank You or continue opening IP Lookup page
      const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.rqa1 + this.$route.query.R
      this.axios.patch(csh.gcu() + currentUri
      ).then(response => {
        if (response.status === 200) {
          let response_data = response.data
          if (response_data.result === 'success') {
            this.selfServiceThankYou = true
          } else {
            console.log(response_data)
          }
        }
      }).catch(function (e) {
        console.log('error', e)
      })
    }
  }
}
</script>
