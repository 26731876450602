<template>
  <div class="container-fluid pr-lg-5 pl-xl-5">
    <div class="col-md-10 col-lg-8 col-xl-7 p-0 mx-auto">
      <b-card no-body>
        <b-card-body>
          <b-row>
            <b-col>
              <h2 class="display-4 font-primary font-weight-semibold mb-0">
                {{ searchIpAddress }}
              </h2>
            </b-col>
<!--            <b-col>-->
<!--              <b-btn size="lg"-->
<!--                     :variant="getVerdict==='clean'? 'success' : 'danger'">{{ getVerdict.toUpperCase() }}-->
<!--              </b-btn>-->
<!--            </b-col>-->
<!--            <b-col v-if="getVerdict!=='clean'" cols="4">{{ verdictDescription[getVerdict] }}</b-col>-->
          </b-row>
        </b-card-body>
        <b-card-body>
          <table class="table card-table">
            <tbody>
            <tr>
              <th scope="row" class="w-25">Results</th>
              <td v-html="getResults"></td>
            </tr>
            <tr>
              <th scope="row" class="w-25">Last Update</th>
              <td>{{ getLocalDateTime(getIpResult('last_update')) }}</td>
            </tr>
            <tr>
              <th scope="row" class="w-25">Country</th>
              <td>{{ getIpResult('country_name') }} ({{ getIpResult('country_code') }})</td>
            </tr>
            <tr>
              <th scope="row" class="w-25">ASN Owner</th>
              <td>{{ getIpResult('asn_owner') }}</td>
            </tr>
            <tr>
              <th scope="row" class="w-25">Latitude / Longitude</th>
              <td>{{ getIpResult('latitude') }} / {{ getIpResult('longitude') }}</td>
            </tr>
            <tr>
              <th scope="row" class="w-25">City</th>
              <td>{{ getIpResult('city') }}</td>
            </tr>
            <tr>
              <th scope="row" class="w-25">Region</th>
              <td>{{ getIpResult('region') }}</td>
            </tr>
            <!--            <tr v-for="(value, key) in searchIpResult" :key="key">-->
            <!--              <th scope="row">{{ key }}</th>-->
            <!--              <td>{{ value }}</td>-->
            <!--            </tr>-->
            </tbody>
          </table>
        </b-card-body>
        <app-search-port-result v-show="searchPortResult" :search-port-result="searchPortResult"></app-search-port-result>
        <b-card-body>
          <b-row align-v="center" class="text-center">
<!--            <b-col>-->
<!--              <b-btn size="md" @click.prevent="$parent.doCleanStart = true" v-scroll-to="'#top'">New Search</b-btn>-->
<!--            </b-col>-->
            <b-col v-show="!searchPortResult">
              <b-btn size="md" @click.prevent="checkPorts" variant="primary">
                <b-spinner v-show="checkingPorts"/>
                Check Open Ports</b-btn>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import csh from "@/csh";
import createSecurity from "../csl";

import AppSearchPortResult from "./AppSearchPortResult";

export default {
  props: ['searchIpAddress', 'searchIpResult', 'searchPortResult', 'doCleanStart'],
  name: "AppSearchIpResult",
  components: {
    AppSearchPortResult
  },
  data: () => ({
    // ipResult: null,
    verdictDescription: {
      'abuser': 'Port Scanner, spammers, service crawlers and Ping Attacker.',
      'threat': 'Hosts that have attempted to exploit, brute force, or execute malware attack.',
      'botnet': 'Malware botnet or C&C hosts.'
    },
    checkingPorts: false
  }),
  computed: {
    getVerdict() {
      if (!this.searchIpResult) return ''
      if (!this.searchIpResult.type &&
          this.searchIpResult.external && this.searchIpResult.detections === 0) {
        return 'clean'
      } else if (this.searchIpResult.channel === 'inippro') {
        return 'abuser'
      } else if (this.searchIpResult.type === 'outip') {
        return 'botnet'
      } else {
        return 'threat'
      }
    },
    getScore() {
      if (!this.searchIpResult) return
      if (!this.searchIpResult.type && this.searchIpResult.detections < 10) {
        return this.searchIpResult.detections * 10
      } else {
        return 100
      }
    },
    getResults() {
      if (!this.searchIpResult) return
      // console.log(this.searchIpResult)
      if (this.searchIpResult.engines_detected.length) {
          return this.searchIpResult.engines_detected.join(',<br/>')
      } else {
          return 'No data found'
      }
    }
  },
  methods: {
    checkPorts() {
      this.checkingPorts = true
      if (this.validateIp()) {
        const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.chp2 + this.searchIpAddress
        this.axios.get(csh.gcu() + currentUri
        ).then(response => {
          if (response.status === 200) {
            this.$parent.searchPortResult = response.data
            if (!this.$parent.searchPortResult) {
              this.showInformation(false, 'No open ports information found!')
            }
          }
          this.checkingPorts = false
        }).catch(function (e) {
          console.log('error', e)
          this.showInformation(true, 'IP check failed! Try again later.')
          this.checkingPorts = false
        })
      } else {
        this.showInformation(true, 'Invalid IP address.')
        this.checkingPorts = false
      }
    },
    validateIp() {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.searchIpAddress)) {
        return true
      }
      return false
    },
    getLocalDateTime(isoDate) {
      let ts = Date.parse(isoDate + 'Z')
      let dt = new Date(ts)
      return dt.toLocaleString()
    },
    getIpResult(keyName) {
      if (!this.searchIpResult) return
      return this.searchIpResult[keyName]
    },
    showInformation(isError = false, infoText) {
      const classes = isError && 'bg-danger text-white' || 'bg-success text-white'
      this.$notify({
        group: 'notifications-bottom-center',
        type: classes,
        title: isError ? 'Error' : 'Information:',
        text: infoText
      })
    },
  }
};
</script>
