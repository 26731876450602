import csh from "./csh";

const arrayRange = (start, stop, step) =>
  Array.from(
  { length: (stop - start) / step + 1 },
  (value, index) => start + index * step
  )

let arrayCharCodes = arrayRange(65, 90, 1)
arrayCharCodes.push.apply(arrayCharCodes, arrayRange(97, 122, 1))

function getRandomValue (arr) {
  // const arrayIndex = Math.floor(Math.random() * (max - min + 1)) + min  // both min and max inclusive
  const arrayIndex = Math.floor(Math.random() * arr.length) // starting with 0 and ending with arr.length-1
  return arr[arrayIndex]
}

function createSecurity () {
  const cipher = csh.cpr(csh.slt2)
  const millis_5_min = 300000
  const epoch = (new Date()).getTime()
  const epoch0 = epoch.toString().slice(0,10)
  const epoch5 = (epoch + millis_5_min).toString().slice(0,10)
  let epochSecured = ""
  for (let i = 0; i < epoch0.length; i++) {
    if (epoch0.charAt(i) === epoch5.charAt(i)) {
      epochSecured += String.fromCharCode(getRandomValue(arrayCharCodes))
      continue
    }
    const j = i > 0 ? 1 : 0
    epochSecured = epochSecured.slice(0, -1) + epoch0.slice(i - j)  // keep last equal digit to increase accuracy
    break
  }
  const securityClear = epochSecured + csh.ske2
  return cipher(securityClear)
}

export default createSecurity
