<template>
  <div id="about" class="landing-block bg-lighter pt-5">
    <div class="container px-3">
      <div class="col-md-10 col-lg-8 col-xl-7 text-center p-0 mx-auto">
        <div class="text-lighter text-tiny font-weight-bold mb-3">COMPANY</div>
        <h1 class="display-4 font-secondary font-weight-semibold mb-4">
          About Us
        </h1>
        <div class="lead text-muted mb-5">
          Starlight Intel provides Cybersecurity protection services to
          businesses who have challenges on technical knowhow and
          technologies.<br />
          We are committed to continue innovate our unique solution to customers
          that is cost effective, simpler and faster.
        </div>
      </div>

      <hr class="landing-separator border-light mx-auto" />

      <div class="row text-center justify-content-center">
        <div class="col-md-4 my-4">
          <div class="text-success mx-auto mb-4">
            <span class="ui-icon font-weight-bolder">
              <i class="ion ion-ios-globe"></i>
            </span>
          </div>
          <h5>Malaysia Office Address</h5>
          <div class="text-muted small">
            Starlight Intelligence Sdn. Bhd. (1351018T)<br /><br />
            31-6, The Boulevard Office, Mid Valley City,<br />
            Lingkaran Syed Putra, 59200 WP Kuala Lumpur, Malaysia<br />
            Tel : +603 2242 0083 Fax : +603 2242 0072
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppAbout",
};
</script>