<template>
  <div id="top">
    <app-navigation @cleanStart="doCleanStart = true"
                    :is-active-search="isActiveSearch"
                    :is-active-self-service="isActiveSelfService"
                    @activeSelfService="isActiveSelfService = true"></app-navigation>
    <app-header :do-clean-start="doCleanStart"
                :activate-self-service="isActiveSelfService"
                @activeSearch="isActiveSearch = true"></app-header>
    <app-threat-map></app-threat-map>
    <app-service></app-service>
    <app-contact></app-contact>
    <app-about></app-about>
    <app-footer></app-footer>
    <notifications group="notifications-bottom-center" position="bottom center" />
  </div>
</template>

<style src="@/vendor/libs/vue-notification/vue-notification.scss" lang="scss"></style>

<script>
import AppNavigation from "./AppNavigation.vue";
import AppHeader from "./AppHeader.vue";
import AppThreatMap from "./AppThreatMap.vue";
import AppService from "./AppService.vue";
import AppContact from "./AppContact.vue";
import AppAbout from "./AppAbout.vue";
import AppFooter from "./AppFooter.vue";

export default {
  name: "AppLayout",
  components: {
    AppNavigation,
    AppHeader,
    AppThreatMap,
    AppService,
    AppContact,
    AppAbout,
    AppFooter,
  },
  data: () => ({
    doCleanStart: false,
    isActiveSearch: false,
    isActiveSelfService: false
  }),
  methods:{
    checkAction() {
      if (this.$route.params.action === 'self-service') {
        this.isActiveSelfService = true
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.checkAction();
    });
  },
};
</script>

