<template>
  <div class="container-fluid pr-lg-5 pl-xl-5" style="margin-top: 30px !important;">
    <div class="col-md-10 col-lg-8 col-xl-7 text-center p-0 mx-auto">
      <h1 class="display-4 font-secondary font-weight-semibold mb-4">
        IP Address Lookup
      </h1>
      <div class="lead text-muted mb-5">
        Check if an IP is listed on the TDR platform.
      </div>
      <div class="input-group">
        <input
            type="text"
            class="form-control form-control-lg bg-lighter border-0 px-3"
            placeholder="IP Address"
            v-model="iad"
            :disabled="chi"
        />
        <span class="input-group-append">
          <button class="btn btn-primary btn-lg" @click.prevent="checkIp" :disabled="chi">
            <b-spinner v-show="chi"/>
            Check
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import csh from "@/csh";
import createSecurity from "../csl";

export default {
  name: "AppSearchIp",
  data: () => ({
    iad: '',  // ipAddress
    chi: false,  // checkingIp
    // resultIp: false,
  }),
  methods: {
    checkIp() {
      this.chi = true
      if (this.validateIp()) {
        const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.chi2 + this.iad
        let self = this
        this.axios.get(csh.gcu() + currentUri
        ).then(response => {
          if (response.status === 200) {
            this.$parent.searchIpResult = response.data
            this.$parent.searchIpAddress = this.iad
            this.iad = ''
          }
          this.chi = false
        }).catch(function (e) {
          console.log('error', e)
          self.showInformation(true, 'IP check failed! Try again later.')
          self.chi = false
        })
      } else {
        this.showInformation(true, 'Invalid IP address.')
        this.chi = false
      }
    },
    validateIp() {
      if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.iad)) {
        return true
      }
      return false
    },
    showInformation(isError = false, infoText) {
      const classes = isError && 'bg-danger text-white' || 'bg-success text-white'
      this.$notify({
        group: 'notifications-bottom-center',
        type: classes,
        title: isError ? 'Error' : 'Information:',
        text: infoText
      })
    },
  }
};
</script>
