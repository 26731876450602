import devtools from '@vue/devtools'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueScrollTo from 'vue-scrollto'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import BootstrapVue from 'bootstrap-vue'
import VueDatamaps from 'vue-datamaps'

Vue.use(Notifications)
Vue.use(BootstrapVue)
Vue.use(VueDatamaps)

Vue.use(VueReCaptcha, { siteKey: '6Lf16SQlAAAAACfcRPsl1dHRxBAlYX83v5n8_5Lr' })

Vue.use(VueScrollTo, {
  container: "body",
  duration: 1000,
  easing: "ease-in-out",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

if (process.env.NODE_ENV === 'development') {
  devtools.connect()
}
