<template>
  <div id="contact" class="landing-block pt-5">
    <div class="container px-3">
      <div class="row justify-content-between">
        <div class="col-lg-5 col-xl-4 text-lg-left text-center mx-auto">
          <div class="text-lighter text-tiny font-weight-bold mb-3">
            CONTACT
          </div>
          <h1 class="display-4 font-secondary font-weight-semibold mb-4">
            Get In Touch
          </h1>
          <p class="text-muted">
            Fill out the contact form to reach us at any time with questions,
            requests, or suggestions. We will respond to you as soon as
            possible.
          </p>
        </div>

        <div class="col-lg-7 col-xl-7 mt-5 mt-lg-0">
          <b-form class="portfolio-block-padding-t px-0 mx-auto" @submit="sendMessage">
            <b-form-group label="BECOME A PARTNER">
              <b-check v-model="message.becomePartner"></b-check>
            </b-form-group>
            <div class="row">
              <div class="col-md-6">
                <b-form-group label="FIRST NAME">
                  <b-input
                      type="text"
                      v-model="message.firstName"
                      :state="state.firstName"
                  />
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group label="LAST NAME">
                  <b-input
                      type="text"
                      v-model="message.lastName"
                      :state="state.lastName"
                  />
                </b-form-group>
              </div>
            </div>
            <b-form-group label="E-MAIL">
              <b-input
                  type="email"
                  v-model="message.email"
                  :state="state.email"
              />
            </b-form-group>
            <b-form-group label="COMPANY NAME">
              <b-input
                  type="text"
                  v-model="message.company"
                  :state="state.company"
              />
            </b-form-group>
            <b-form-group label="PHONE NUMBER" description="Optional">
              <b-input
                  type="tel"
                  v-model="message.phone"
              />
            </b-form-group>
            <b-form-group label="TITLE">
              <b-input
                  type="text"
                  v-model="message.title"
                  :state="state.title"
              />
            </b-form-group>
            <b-form-group label="MESSAGE">
              <b-textarea
                  rows="6"
                  v-model="message.content"
                  :state="state.content"
              ></b-textarea>
            </b-form-group>
            <b-btn variant="primary" size="lg" type="submit" :disabled.sync="messageSending">
              <b-spinner v-show="messageSending" />
              SEND A MESSAGE
            </b-btn>
            <div class="text-lighter text-tiny font-weight-bold mt-3">
              Your personal data is protected in accordance with the Personal
              Data Protection Act 2010 of Malaysia (“ACT 709 PDPA 2010“)
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import csh from "@/csh"
import createSecurity from "../csl";  // '../csh' also working

// function sleep(milliseconds) {
//   const date = Date.now();
//   let currentDate = null;
//   do {
//     currentDate = Date.now();
//   } while (currentDate - date < milliseconds);
// }

export default {
  name: "AppContact",
  data: () => ({
    messageSending: false,
    message: {
      becomePartner: false,
      firstName: '',
      lastName: '',
      email: '',
      company: '',
      phone: '',
      title: '',
      content: '',
    },
    state: {
      firstName: null,
      lastName: null,
      email: null,
      company: null,
      title: null,
      content: null,
    }
  }),
  methods: {
    validateMessage() {
      this.state.firstName = this.message.firstName.trim() !== ''
      this.state.lastName = this.message.lastName.trim() !== ''
      this.state.email = this.message.email.trim() !== ''
      this.state.company = this.message.company.trim() !== ''
      this.state.title = this.message.title.trim() !== ''
      this.state.content = this.message.content.trim() !== ''

      return Object.values(this.state).every(Boolean)
    },
    sendMessage(evt) {
      evt.preventDefault()
      this.messageSending = true
      if (this.validateMessage()) {
        const currentUri = csh.avr2 + String.fromCharCode(47) + createSecurity() + csh.msd2
        this.axios.post(csh.gcu() + currentUri,
            this.message
        ).then(response => {
          if (response.status === 201) {
            console.log(response.data)
            this.showInformation(false, 'Message successfully sent!')
            this.resetMessageFields()
          }
          this.messageSending = false
        }).catch(function (e) {
          console.log('error', e)
          this.showInformation(true, 'Message sending failed! Try again later.')
          this.messageSending = false
        })
      } else {
        this.showInformation(true, 'Message not sent! Correct the error fields and try again.')
        this.messageSending = false
      }
    },
    showInformation (isError=false, infoText) {
      const classes = isError && 'bg-danger text-white' || 'bg-success text-white'
      this.$notify({
        group: 'notifications-bottom-center',
        type: classes,
        title: isError ? 'Error' : 'Information:',
        text: infoText
      })
    },
    resetMessageFields () {
      this.message.becomePartner = false
      this.message.firstName = ''
      this.message.lastName = ''
      this.message.email = ''
      this.message.company = ''
      this.message.phone = ''
      this.message.title = ''
      this.message.content = ''

      this.state.firstName = null
      this.state.lastName = null
      this.state.email = null
      this.state.company = null
      this.state.title = null
      this.state.content = null
    }
  }
};
</script>