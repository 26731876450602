function dcs(o,n) {
  let p = o.slice(5) + o.slice(0,5)
  return atob(eql(p,n))
}

function eql(o,n) {
  return o + (String.fromCharCode(61)).repeat(n)
}

export default dcs
