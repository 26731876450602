<template>
  <div class="theme-bg-white">
    <div id="service" class="landing-block pt-5">
      <div class="container px-3">
        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 col-xl-4 text-lg-left text-center mx-auto">
            <div class="text-lighter text-tiny font-weight-bold mb-3">
              INTRODUCTION
            </div>
            <h1 class="display-4 font-secondary font-weight-semibold mb-4">
              TDR Service
            </h1>
            <p class="text-muted">
              We turn manual blocking actions into automation. With our
              proprietary machine learning logic and threat intelligence
              database, we able to make accurate and rapid response to threats
              without user intervention.
            </p>
          </div>

          <div class="col-lg-7 col-xl-7 mt-5 mt-lg-0">
            <img src="img/tdr.png" alt class="w-100" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppService",
};
</script>