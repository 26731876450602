import Vue from 'vue'
import VueRouter from 'vue-router'

import Layout from "./components/Layout.vue"
// import Home from "./Home.vue"
// import Messages from "./Messages.vue";

Vue.use(VueRouter)

const router = new VueRouter({
    base: '/',
    mode: 'history',
    routes: [{
      path: '/',
      component: Layout
      //   children: [{
      //     path: '',
      //     component: () => Home
      //   }, {
      //     path: 'messages',
      //     component: () => Messages
      //   }]
    }, {
      path: '/:action',
      component: Layout
    }]
  })
  
  export default router
