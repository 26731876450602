<template>
  <nav class="footer bg-white pt-4">
    <div class="container text-center py-4">
      <div class="pb-3">
        <a href="#" class="footer-text text-big font-weight-bolder text-expanded">STARLIGHT</a>
        <a href="#" class="footer-text text-big text-expanded">Intelligence</a>
        <span class="align-top">©</span>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>