<template>
  <!-- Navbar -->
  <nav class="landing-navbar navbar navbar-expand-lg navbar-light fixed-top pt-lg-4">
    <div class="container-fluid px-3">
      <div class="app-brand">
        <a href="javascript:void(0)" @click="cleanStart" v-scroll-to="'#top'">
          <img src="../assets/logo.png" alt="" class="app-brand-img ui-w-160">
        </a>
      </div>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#landing-navbar-collapse">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="landing-navbar-collapse">
        <div class="navbar-nav align-items-lg-center ml-auto">
          <b-btn v-show="isActiveSearch" variant="default" class="btn-s md-btn-flat mr-3" @click="cleanStart" v-scroll-to="'#top'">New Search</b-btn>
          <a class="anchor-link nav-item nav-link" v-scroll-to="'#threatMap'" href="#">Threat Map</a>
          <a class="anchor-link nav-item nav-link" v-scroll-to="'#service'" href="#">TDR Service</a>
          <a class="anchor-link nav-item nav-link" v-scroll-to="'#contact'" href="#">Contact Us</a>
          <a class="anchor-link nav-item nav-link" v-scroll-to="'#about'" href="#">About Us</a>
          <a v-show="!isActiveSelfService" class="anchor-link nav-item nav-link" @click="activeSelfService" v-scroll-to="'#top'" href="#">Self Service</a>
          <a v-show="isActiveSelfService" class="anchor-link nav-item nav-link" @click="cleanStart" v-scroll-to="'#top'" href="#">IP Lookup</a>
          <a class="nav-item nav-link" href="https://www.facebook.com/starlightintel">
            <i class="ion ion-logo-facebook text-twitter text-big align-middle"></i>
            <span class="d-lg-none align-middle">&nbsp; Facebook</span>
          </a>
          <a class="nav-item nav-link mr-lg-4" href="https://www.linkedin.com/company/starlightintel">
            <i class="ion ion-logo-linkedin text-facebook text-big align-middle"></i>
            <span class="d-lg-none align-middle">&nbsp; LinkedIn</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
  <!-- / Navbar -->
</template>

<script>
export default {
  props: ['isActiveSearch', 'isActiveSelfService'],
  name: "AppNavigation",
  methods: {
    cleanStart() {
      this.$emit('cleanStart')
    },
    activeSelfService() {
      this.$emit('activeSelfService')
    }
  }
};
</script>